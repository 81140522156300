import { format, isValid, parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const dateFormatMap: Record<string, string> = {
  user: "dd/MM/yyyy",
  system: "yyyy-MM-dd",
};

const dateTimeFormatMap: Record<string, string> = {
  user: "dd/MM/yyyy HH:mm",
  system: "yyyy-MM-dd HH:mm",
};

const dateTimeSecondFormatMap: Record<string, string> = {
  user: "dd/MM/yyyy HH:mm:SSS",
  system: "yyyy-MM-dd HH:mm:SSS",
};

const toDateObject = (dateStr: string): Date => {
  let dateOnlyStr = dateStr;
  if (dateStr.length > 10) {
    dateOnlyStr = dateStr.substring(0, 10);
  }
  return parse(dateOnlyStr, dateFormatMap.system, new Date());
};

const toDateTimeObject = (dateTimeStr: string): Date => {
  return parse(dateTimeStr, dateTimeFormatMap.system, new Date());
};

const formatDate = (dateStr: string | undefined, strFormat: string): string => {
  const dateObj = parse(dateStr ?? "", dateFormatMap.system, new Date());
  return isValid(dateObj) ? format(dateObj, dateFormatMap[strFormat]) : "";
};

const formatDateTime = (dateStr: string | undefined, strFormat: string): string => {
  const date = new Date(dateStr ?? "");
  if (!isValid(date)) return "";
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, dateTimeFormatMap[strFormat]);
};

const toUserDateFormat = (dateStr: string): string => formatDate(dateStr, "user");

const toSystemDateFormat = (dateStr: string): string => formatDate(dateStr, "system");

const toUserDateTimeFormat = (dateTimeStr: string): string =>
  formatDateTime(dateTimeStr, "user");

const getAge = (dob: Date): number => {
  const ageDifMs = Date.now() - dob.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const getAgeFromStr = (dob: string): number => {
  return getAge(toDateObject(dob));
};

const isPastDate = (dateObj: Date): boolean => {
  if (dateObj) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return dateObj <= now;
  }
  return true;
};

export {
  dateFormatMap,
  dateTimeFormatMap,
  dateTimeSecondFormatMap,
  formatDate,
  getAge,
  getAgeFromStr,
  isPastDate,
  toDateObject,
  toDateTimeObject,
  toUserDateFormat,
  toUserDateTimeFormat,
  toSystemDateFormat,
};
